export const FooterScreen = () => {
    return (
        <div className="footer tran5s wow fadeInUp">
            <div className="footer_wapper pt-50 md-pt-10 pb-50">
                <div className="container">
                    <div className="footer_content">
                        <div className="row mt-10">

                            <div className="col-lg-2 md-mt-30">
                                <div className="d-flex logo order-lg-0"><a href="/" className="d-block"><img src="images/footer/footer-image.png" alt="" /></a></div>
                            </div>


                            <div className="col-lg-10 md-mt-30">
                                <div>
                                    <b>Social media has revolutionized the way businesses interact with their audience, promote their products or services, and build brand awareness. Social media marketing has become an essential component of modern marketing strategies. This essay explores the significance of social media marketing and its impact on businesses in the digital age. </b>
                                </div>
                                <div className="mt-50">
                                    <b>The Evolution of Social Media Marketing</b>
                                    <p> Social media platforms like Facebook, Twitter, Instagram, and LinkedIn have evolved from being personal networking sites to powerful marketing tools. Businesses recognized the potential of these platforms to reach a global audience and engage with customers on a more personal level. As a result, they started leveraging social media for marketing purposes.</p>
                                </div>
                                <div className="mt-10">
                                    <b> Challenges and Ethical Considerations</b>
                                    <p>While social media marketing offers numerous benefits, it also presents challenges and ethical considerations. Privacy concerns, online harassment, misinformation, and data breaches are among the issues that businesses must navigate carefully. Ethical marketing practices and a strong commitment to protecting user data are essential to building and maintaining trust. </p>

                                </div>
                                <div className="mt-10">
                                    <b> The Future of Social Media Marketing</b>
                                    <p>  The field of social media marketing is constantly evolving. Trends such as influencer marketing, live streaming, and augmented reality (AR) are gaining prominence. Businesses need to adapt to these changes to remain competitive and relevant. Moreover, as new platforms emerge and existing ones continue to evolve, staying current with the latest marketing tools and strategies is vital.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row col-xl-12 mt-100 md-mt-30">
                    </div>
                </div>
            </div>
        </div>
    )
}