import { NavigationScreen } from "./Navigation"
import { FooterScreen } from "./footer"

export const ServicesScreen = () => {
    return (
        <div className="main-page-wrapper">
            <NavigationScreen />
            <div className="service-all pt-120 md-pt-60 position-relative tran5s wow fadeInUp">
                <div className="container-fluid">
                    <div className="banner-rapper-two banner-rapper-three pb-60 position-relative md-pl-0">
                        <div className="row d-flex">
                            <div className="col-xl-6">
                                <div className="left-banner position-relative mt-120 lg-mt-60">
                                    <div className="mb-40"><h3 className="h3" style={{color:'#FF6915'}}>Web Design & Competitor Analysis</h3></div>
                                    <div className="mb-40 pr-110"><p className="para-one">This is the main factor that sets us apart from our competition and allows us to deliver a specialist business consultancy service.</p></div>
                                    <div className="pr-110 hidden"><p className="para-two opacity">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised word</p></div>
                                    <div className="left-bottom-banner mt-80">
                                        <div className="mb-40"><span className="tech-partner">Our Tech:</span></div>
                                        <div className="">
                                            <a href=""><img src="images/icon/tech-02.svg" alt="figma" /></a>
                                            <a href=""><img src="images/icon/tech-03.svg" alt="adobe XD" /></a>
                                            <a href=""><img src="images/icon/tech-04.svg" alt="Adobe illustator" /></a>
                                            <a href="" style={{ width: '10%', marginRight: '25px' }}><img src="images/icon/react.png" alt="react" /></a>
                                            <a href="" style={{ width: '10%', marginRight: '25px' }}><img src="images/icon/nodejs.png" alt="nodejs" /></a>
                                            <a href="" style={{ width: '10%', marginRight: '25px' }}><img src="images/icon/adwords.png" alt="adsense" /></a>
                                            <a href="" style={{ width: '10%', marginRight: '25px' }}><img src="images/icon/fbads.png" alt="facebook ads" /></a>
                                            <a href="" style={{ width: '10%', marginRight: '25px' }}><img src="images/icon/instagram-ads.png" alt="facebook ads" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-5">
                                <div className="right-banner position-relative lg-mt-50">
                                    <div className="banner_two_slider">
                                        <img src="images/banner/banner-11.png" alt="" className="banner-01" />
                                        <img src="images/banner/banner-15.png" alt="" className="banner-01" />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="right-aside tran3s wow fadeInUp">
                <div className="offcanvas offcanvas-end" id="offcanvasRight" aria-labelledby="offcanvasRight">
                    <div className="offcanvas-header">
                        <div className="d-flex logo order-lg-0"><a href="" className="d-block"><img src="images/logo/logo.png" alt="" /></a></div>
                        <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"><i className="bi bi-x-square-fill"></i></button>
                    </div>
                    <div className="offcanvas-body">
                        <div className="info-widget">
                            <h6 className="color-two mb-20">About Us</h6>
                            <p className="mb-30">
                                We work with forward-thinking companies to craft and produce impactful solutions through website & mobile app experiences.
                                You will work closely with skilled Dolor in reprehenderit in voluptate velit esse cillum.
                            </p>
                        </div>
                        <div className="info-widget info-widget2">
                            <h6 className="color-two mb-20">Contact Us</h6>
                            <p>
                                <i className="bi bi-geo-alt"></i>
                                3739 Balboa Street, San Francisco, CA 94121</p>
                            <p>
                                <i className="bi bi-telephone"></i>
                                +0989 7876 9865 9
                            </p>
                            <p>
                                <i className="bi bi-envelope-open"></i>
                                hello@templatemr.com
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="design-service pt-100 pb-100 md-pt-60 md-pb-120 position-relative tran5s wow fadeInUp" style={{ visibility: 'visible', 'animationName': 'fadeInUp' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="left-service">
                                <div className="mb-20"><h5 className="h5 fw-700">Website Design &amp; Build</h5></div>
                                <div className=""><p className="fs-24 color-black">Websites can no longer just make sales, as in this day and age they also need to be able to engage and entertain. This is where we can help.</p></div>
                                <div className="mb-10"><span className="include">Services Include:</span></div>
                                <div className="row gy-4 pr-120">
                                    <div className="col-lg-6">
                                        <div className="design-01 text-center">
                                            <div className="icon"><img src="images/icon/design-01.svg" alt="" /></div>
                                            <p className="text-center mt-10 color-black">Full custom website build</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="design-01 text-center">
                                            <div className="icon"><img src="images/icon/design-02.svg" alt="" /></div>
                                            <p className="text-center color-black">Marketing microsite build</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="design-01 text-center">
                                            <div className="icon"><img src="images/icon/design-03.svg" alt="" /></div>
                                            <p className="text-center mt-10 color-black">Competition website build</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="design-01 text-center">
                                            <div className="icon"><img src="images/icon/design-04.svg" alt="" /></div>
                                            <p className="text-center color-black">On-going maintenance &amp; support</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="right-service position-relative pl-50 md-pl-0 md-mt-30">
                                <img src="images/about/about-09.png" alt="" className="about-09" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterScreen />
        </div>
    )
}