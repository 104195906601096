import {AboutUs} from "./AboutUs"
import {BusinessGrowthScreen} from "./BusinessGrowth"
import {NavigationScreen} from "./Navigation"
import {WhatWeOfferScreen} from "./WhatWeOffer"
import {BlogScreen} from "./blog"
import {CaseStudiesScreen} from "./caseStudies"

import {CreativeStudioScreen} from "./creativeStudio"
import {FooterScreen} from "./footer"
import {ReadyToGrowScreen} from "./readyToGrow"
import {TeamScreen} from "./team"
import {TestimonialScreen} from "./testimonial"
import {WhyUsScreen} from "./whyUs"

export const HomeScreen=(): React.ReactElement => {
    return (
        <div className="main-page-wrapper">
            <NavigationScreen />
            <CreativeStudioScreen />
            <WhyUsScreen />
            <WhatWeOfferScreen />
            <button className="scroll-top">
                <i className="bi bi-arrow-up-short"></i>
            </button>
            <FooterScreen />
        </div>
    )
}