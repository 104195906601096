import React from "react"

export const CreativeStudioScreen=() => {
    return (
        <div className="banner-two pt-120 md-pt-60 position-relative tran5s wow fadeInUp">
            <div className="container-fluid">
                <div className="banner-rapper-two pb-60 position-relative">
                    <div className="shapes banner-shape-01">
                    </div>
                    <div className="shapes banner-shape-02">
                    </div>
                    <div className="row d-flex">
                        <div className="col-xl-6">
                            <div className="left-banner position-relative mt-100 md-mt-60">
                                <img src="images/shape/shape-13.png" alt="" className="shapes shape-13" />
                                <div className=""><h1 className="h1 fw-700 color-one mb-50 position-relative">Digital <br /> Solution <br /> With <span className="fw-400">Us</span></h1></div>
                                {/* <div className=""><a href="https://www.instagram.com/socialhive_official/" target="_blank" className="custom-btn-one">Find Solution</a></div> */}
                                <div className="left-bottom-banner pt-60">
                                    <div className="border-100 mb-30"></div>
                                    <div className="row d-flex align-items-center gx-0">
                                        <div className="col-lg-5">
                                            <p className="fs-22 fw-500 md-mb-20">Want to work with us?</p>
                                        </div>
                                        <div className="col-lg-6">
                                            <p className="normal-p">support@socialhivve.in | +91-9554268682</p>
                                        </div>
                                         
                                    </div>
                                    <div className="border-100 mt-30"></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="right-banner position-relative pl-50 lg-pl-0 lg-mt-50">
                                <div className="banner_two_slider">
                                    <img src="images/banner/banner-11.png" alt="" className="banner-01" />
                                    <img src="images/banner/banner-02.png" alt="" className="banner-02" />
                                    <img src="images/banner/banner-12.png" alt="" className="banner-01" />
                                    
                                </div>
                                {/* <img src="images/shape/shape-14.png" alt="" className=" shapes shape-14" /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}