import { Link } from "react-router-dom";

export const NavigationScreen = () => {
	return (
		<div className="theme-main-menu sticky-menu theme-menu-one theme-menu-two tran5s wow fadeInUp">
			<div className="inner-content">
				<div className="d-flex align-items-center justify-content-between">
					<div className="d-flex logo order-lg-0">
						<a href="/" className="d-block">
							<img src="images/logo/SOCIAL-HIVVE-logo.png" alt="" />
						</a>
					</div>

					<div className="right-wiget ms-auto d-lg-flex justify-content-center order-lg-3 ">
						<nav className="navbar navbar-expand-lg order-lg-2">
							<button className="navbar-toggler d-block d-lg-none" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
								<span></span>
							</button>

							<div className="collapse navbar-collapse" id="navbarNav">
								<ul className="navbar-nav me-auto mb-2 mb-lg-0">
									<li className="nav-item dropdown">
										<span className="nav-link active dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{textAlign:'right'}} >
											<a href="mailto:support@socialhivve.in">Mail: support@socialhivve.in </a><br />
											<a href="tel:9195554268682">WA: +91-9554268682</a>
										</span>
									</li>
								</ul>
							</div>
							<div>
								<a className="custom-btn-one" href="https://www.instagram.com/socialhive_official/" target="_blank">
									Follow Us on instagram
								</a>
							</div>
						</nav>
					</div>
				</div>
			</div>
		</div>
	);
};
