import { NavigationScreen } from "../pages/Navigation"
import { FooterScreen } from "../pages/footer"

export const AboutUsScreen = () => {
    return (
        <div>
            <NavigationScreen />
            <div className="banner-about mb-100 md-mb-50 tran5s wow fadeInUp" style={{ background: 'linear-gradient(90deg, rgb(123, 123, 123) 0%, rgba(84, 81, 81, 0.73) 23.44%, rgba(27, 25, 25, 0) 100%),url(/images/about/about-header.jpg) center center no-repeat' }}>
                <div className="container pt-286 pb-170 md-pt-150 md-pb-90">
                    <div className="row d-flex align-items-center">
                        <div className="text-center">
                            <h2 className="h2 mb-20 md-mb-10 position-relative">About us</h2>
                            <p className=""><a href="" className="">Home - About us</a></p>
                        </div>
                    </div>
                </div>
            </div>
            <section className="about-three md-mb-50 position-relative tran5s wow fadeInUp">
                <div className="container">
                    <div className="row d-flex align-items-center justify-content-center">
                        <div className="text-rapper text-center">
                            <h4 className="mb-30 color-white">An agency like no other. Results to match.</h4>
                            <p className="color-white">We work with forward-thinking companies to craft and produce impactful solutions through website & mobile app experiences.You will work closely with skilled Dolor in reprehenderit in voluptate velit esse cillum</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="work-history-one lg-mt-80 lg-mb-80 work-history-two wow fadeInUp">
                <div className="container">
                    <div className="work-rapper-one pt-60 pb-60">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="work-one text-center md-mb-30 position-relative">
                                    <div className=""><h4 className="work heading fw-700 color-two"><span className="counter">10</span>k+</h4></div>
                                    <div className=""><span className="fw-500 fs-20 upercase">Experienced designers</span></div>
                                    <div className=""><span className="fs-16 opacity">Our company has 100 expert</span></div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="work-one text-center md-mb-30 position-relative">
                                    <div className=""><h4 className="work heading fw-700 color-two"><span className="counter">10</span>k+</h4></div>
                                    <div className=""><span className="fw-500 fs-20 upercase">SATISFIED CUSTOMERS</span></div>
                                    <div className=""><span className="fs-16 opacity">Our company satisfied customer</span></div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="text-center position-relative">
                                    <div className=""><h4 className="work heading fw-700 color-two"><span className="counter">120</span>k+</h4></div>
                                    <div className=""><span className="fw-500 fs-20 upercase">COMPLETED CASSES</span></div>
                                    <div className=""><span className="fs-16 opacity">We have done 1200 Cassses</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about-two about-four pt-260 pb-80 mb-50 lg-pt-80 md-pb-40 md-mb-50 position-relative tran5s wow fadeInUp hidden">
                <div className="container">
                    <div className="row d-flex align-items-end">
                        <div className="col-xl-6">
                            <div className="left-about position-relative lg-mb-50">
                                <img src="images/about/about-04.png" alt="" className="shapes about-04" />
                                <img src="images/about/about-05.png" alt="" className="about-05" />
                                <div className="shapes shapes-02"></div>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className=""><span className="span-two">Business GROWTH</span></div>
                            <div className="heading-shape mt-10 pb-30"><h4 className="h4 position-relative fw-700">Best Digital <br /> Creative Agency</h4></div>
                            <div className=""><p className="fw-500">There are many variations of passages of lorem in free market to available, but the majority have suffered alteration in some form, by injected humour, or randomised words</p></div>
                            <div className="mt-30 mb-20">
                                <ul className="about-list d-flex align-items-center fw-500 fs-20">
                                    <li>For marketing professionals</li>
                                    <li>Event Marketing Opportunity</li>
                                </ul>
                            </div>
                            <div className="para-text mb-20"><p>We Design & Build Powerful Websites</p></div>
                            <div className=""><p className="opacity fw-500">Variations of passages of lorem in free market to available, but the majority have suffered alteration in some form, by injected humour, or randomised words</p></div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="choose-one mb-140 lg-mb-80 tran5s wow fadeInUp hidden">
                <div className="container">
                    <div className="choose-one-rapper md-pt-50 position-relative">
                        <img src="images/shape/shape-05.svg" alt="" className="shapes shape-05" />
                        <img src="images/shape/shape-06.svg" alt="" className="shapes shape-06" />
                        <div className="row d-flex align-items-end">
                            <div className="col-lg-6">
                                <div className="left-choose position-relative md-mb-80">
                                    <div className=""><span className="span-two">WHY US</span></div>
                                    <div className=""><h4 className="h4 position-relative fw-700 mt-10 mb-40">We Give You Digital Solutions</h4></div>
                                    <div className=""><p className="mb-30 color-white">Sed lectus vestibulum mattis ullamcorper velit sed ullamcorperbi. Fusce id velit ut tortor pretium viverra suspendisse fuis ultricies lacus sed turpis tincidunt id aliquet. Sed viverra tellus sed lectus vestibulum mattis</p></div>
                                    <div className="progress_one mb-20">
                                        <span className="fs-20 fw-500 position-relative">Website Development</span>
                                        <div className="progress mt-20 position-relative">
                                            <div className="progress-bar bar-width-one bg-dark" role="progressbar" style={{ width: '85%' }} aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}></div>
                                        </div>
                                    </div>
                                    <div className="progress_two mb-20">
                                        <span className="fs-20 fw-500 position-relative">Digital Products</span>
                                        <div className="progress mt-20 position-relative">
                                            <div className="progress-bar bar-width-two bg-dark" role="progressbar" style={{ width: '75%' }} aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}></div>
                                        </div>
                                    </div>
                                    <div className="progress_three">
                                        <span className="fs-20 fw-500 position-relative">Website Development</span>
                                        <div className="progress mt-20 position-relative">
                                            <div className="progress-bar bar-width-three bg-dark" role="progressbar" style={{ width: '90%' }} aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="right-choose position-relative pl-20 md-pl-0">
                                    <img src="images/about/about-02.png" alt="" className="about-02" />
                                    <img src="images/shape/shape-07.svg" alt="" className="shape-07" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <FooterScreen />
        </div>
    )
}