import {BrowserRouter,Link,Route,Routes} from 'react-router-dom';
import './App.css';
import {HomeScreen} from './pages';
import {ServicesScreen} from './pages/services';
import {AboutUs} from './pages/AboutUs';
import {ContactUsPage} from './routes/contact';
import { AboutUsScreen } from './routes/aboutUs';
import React from 'react';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<HomeScreen />} />
        <Route path='/services' element={<ServicesScreen />} />
        <Route path="/about" element={<AboutUsScreen/>} />
        <Route path="/contact" element={<ContactUsPage/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
