export const WhatWeOfferScreen = () => {
    return (
        <section className="our-offer-one our-offer-three position-relative pb-50 md-pb-10 tran5s wow fadeInUp">
            <div className="container">
                <div className="offer-rapper-one position-relative pt-80 pb-80 md-pt-40 md-pb-40">
                    <div className="shape_001"></div>
                    <div className="shape_002"></div>
                    <div className="shape_003"></div>
                    <div className="text-center mb-80 md-mb-40">
                        <div className=""><span className="span-two">WHAT WE OFFER</span></div>
                        <div className=""><h4 className="heading_shape position-relative h4 fw-700 pt-30">What We’re Offering Creative <br /> Digital Service</h4></div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-lg-4 md-mb-30">
                            <div className="offer-one pt-40 pb-40 pl-40 pr-40">
                                <div className="offer-pic d-flex align-items-center justify-content-between mb-30">
                                    <img src="images/icon/offer_05.svg" />
                                    <div className=""><a href="" className="offer-link"> <img src="images/icon/arrow_01.svg" /></a></div>
                                </div>
                                <div className="mb-30"><h6 className="h6 fw-700"><a href="#" className="">Digital Marketing</a></h6></div>
                                <div className="mb-30"><p className="fs-16">A range of solutions, including search engine optimization (SEO), pay-per-click advertising, and social media marketing.</p></div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 md-mb-30">
                            <div className="offer-one pt-40 pb-40 pl-40 pr-40">
                                <div className="offer-pic d-flex align-items-center justify-content-between mb-30">
                                    <img src="images/icon/offer_01.svg" />
                                    <div className=""><a href="" className="offer-link"> <img src="images/icon/arrow_01.svg" /></a></div>
                                </div>
                                <div className="mb-30"><h6 className="h6 fw-700"><a href="" className="">SEO</a></h6></div>
                                <div className="mb-30"><p className="fs-16">Optimizing your website's individual pages by including relevant keywords in titles, headings, meta descriptions, and content</p></div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 md-mb-30">
                            <div className="offer-one pt-40 pb-40 pl-40 pr-40">
                                <div className="offer-pic d-flex align-items-center justify-content-between mb-30">
                                    <img src="images/icon/offer_06.svg" />
                                    <div className=""><a href="" className="offer-link"> <img src="images/icon/arrow_01.svg" /></a></div>
                                </div>
                                <div className="mb-30"><h6 className="h6 fw-700"><a href="" className="">SMM</a></h6></div>
                                <div className="mb-30"><p className="fs-16">Effective social media marketing can help businesses connect with their audience, increase brand visibility, and drive traffic and conversions</p></div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 md-mb-30 mt-50">
                            <div className="offer-one pt-40 pb-40 pl-40 pr-40">
                                <div className="offer-pic d-flex align-items-center justify-content-between mb-30">
                                    <img src="images/icon/offer_06.svg" />
                                    <div className=""><a href="" className="offer-link"> <img src="images/icon/arrow_01.svg" /></a></div>
                                </div>
                                <div className="mb-30"><h6 className="h6 fw-700"><a href="" className="">Web Development</a></h6></div>
                                <div className="mb-30"><p className="fs-16">Effective Website can help businesses connect with their audience, increase brand visibility, and drive traffic and conversions</p></div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 md-mb-30 mt-50">
                            <div className="offer-one pt-40 pb-40 pl-40 pr-40">
                                <div className="offer-pic d-flex align-items-center justify-content-between mb-30">
                                    <img src="images/icon/offer_06.svg" />
                                    <div className=""><a href="" className="offer-link"> <img src="images/icon/arrow_01.svg" /></a></div>
                                </div>
                                <div className="mb-30"><h6 className="h6 fw-700"><a href="" className="">Mobile app Development</a></h6></div>
                                <div className="mb-30"><p className="fs-16"> </p></div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 md-mb-30 mt-50">
                            <div className="offer-one pt-40 pb-40 pl-40 pr-40">
                                <div className="offer-pic d-flex align-items-center justify-content-between mb-30">
                                    <img src="images/icon/offer_06.svg" />
                                    <div className=""><a href="" className="offer-link"> <img src="images/icon/arrow_01.svg" /></a></div>
                                </div>
                                <div className="mb-30"><h6 className="h6 fw-700"><a href="" className="">Competitor Analysis</a></h6></div>
                                <div className="mb-30"><p className="fs-16"> </p></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}