import React from "react"
import {NavigationScreen} from "../pages/Navigation"
import {FooterScreen} from "../pages/footer";
import TextField from '@mui/material/TextField';
import {TextareaAutosize} from "@mui/material";

export const ContactUsPage=() => {

    const [form,setForm]=React.useState<any>({
        fname: {
            value: '',
            error: false,
            errorMessage: "Please fill in this field"
        },
        lname: {
            value: '',
            error: false,
            errorMessage: "Please fill in this field"
        },
        email: {
            value: '',
            error: false,
            errorMessage: "Please fill in this field"
        },
        message: {
            value: '',
            error: false,
            errorMessage: "Please fill in this field"
        },
        phone: {
            value: '',
            error: false,
            errorMessage: "Please fill in this field"
        }
    });

    const [isSubmitted,setIsSubmitted]=React.useState<boolean>(false)
    const [showErrorMessage,setShowErrorMessage]=React.useState(false)


    const updateField=(field: string,value: string) => {
        console.log("value",value)
        setForm({
            ...form,
            [field]: {
                ...form[field],
                value: value
            }
        })
    };

    const sendMail=() => {
        const keys=Object.keys(form);
        const errorFields: Array<string>=[]
        keys.forEach((key: any) => {
            if(!form[key].value) {
                errorFields.push(key)
            }
        });
        console.log("errorFields",errorFields)

        if(errorFields.length>0) {
            setShowErrorMessage(true)
        } else {
            // send mail
            fetch('https://appzat.com/mailer',
                {
                    method: 'post',
                    body: JSON.stringify({
                        fname: form.fname.value,
                        lname: form.lname.value,
                        email: form.email.value,
                        message: form.message.value,
                        phone: form.phone.value
                    })
                }
            ).then((response) => {
                console.log("response")
            }).catch((error) => {
                console.log("error")
            })
        }
    }

    return (
        <div>
            <NavigationScreen />
            <div className="banner-about md-pt-50 tran5s wow fadeInUp" style={{background: 'linear-gradient(90deg, rgb(123, 123, 123) 0%, rgba(84, 81, 81, 0.73) 23.44%, rgba(27, 25, 25, 0) 100%),url(images/banner/banner-03.png) center center no-repeat'}}>
                <div className="container pt-286 pb-170 md-pt-150 md-pb-90">
                    <div className="row d-flex align-items-center">
                        <div className="text-center">
                            <h2 className="h2 mb-20 md-mb-10 position-relative">Contact us</h2>
                            <p className=""><a href="" className="">Home - Contact</a></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contact-form pt-120 md-pt-40 position-relative tran5s wow fadeInUp">
                <div className="contact-form-rapper">
                    <section className="about-three">
                        <div className="container">
                            <div className="row d-flex align-items-center justify-content-center">
                                <div className="text-rapper text-center">
                                    <h4 className="mb-30">Feel free to contact us or just say hi!</h4>
                                    <p>We are here to answer any question you may have.</p>
                                </div>
                            </div>
                            <div className="my-form position-relative">
                                {showErrorMessage&&<p className="pt-10 pb-10" style={{color: 'red'}}><b>Please Enter the required fields</b></p>}
                                <form>
                                    <div className="row g-lg-4 ms-auto">
                                        <div className="col-lg-6 position-relative">
                                            <TextField fullWidth label="First Name*" variant="outlined" error={form.fname.error} value={form.fname.value} onChange={(e) => {updateField('fname',e.target.value)}} />
                                        </div>

                                        <div className="col-lg-6 position-relative">
                                            <TextField fullWidth label="Last Name*" variant="outlined" error={form.lname.error} value={form.lname.value} onChange={(e) => {updateField('lname',e.target.value)}} />
                                        </div>

                                        <div className="col-lg-6 position-relative">
                                            <TextField fullWidth label="Email*" variant="outlined" error={form.email.error} value={form.email.value} onChange={(e) => {updateField('email',e.target.value)}} />
                                        </div>

                                        <div className="col-lg-6 position-relative">
                                            <TextField fullWidth label="Phone*" variant="outlined" error={form.phone.error} value={form.phone.value} onChange={(e) => {updateField('phone',e.target.value)}} />
                                        </div>

                                        <div className="col-lg-12 mb-60 position-relative">
                                            <TextareaAutosize style={{width:'100%', padding:10}} minRows={5} placeholder="What are you looking for ? Digital marketing, or website development" value={form.message.value} onChange={(e) => {updateField('message',e.target.value)}}
                                            />
                                        </div>
                                    </div>

                                    <div className="sent-massage" style={{textAlign: 'right'}}>
                                        <a href="javascript:void(0)" onClick={() => {sendMail()}} className="custom-btn-one">Send a message</a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <FooterScreen></FooterScreen>
        </div>
    )
}