export const WhyUsScreen = () => {
    return (

        <section className="choose-one choose-two position-relative pb-140 md-pb-60 tran5s wow fadeInUp">
            <div className="container">
                <div className="choose-one-rapper position-relative pt-90 md-pt-40">
                    <div className="shape_003"></div>
                    <div className="position-relative"><img src="images/shape/shape-20.svg" alt="" className="shapes shape-06" /></div>
                    <div className="row d-flex align-items-end">
                        <div className="col-xl-6">
                            <div className="left-choose lg-mb-50">
                                <div className=""><span className="span-two">WHY US</span></div>
                                <div className=""><h4 className="h4 position-relative fw-700 pt-20 mb-40">We Give You <br /> Digital Solutions</h4></div>
                                <div className=""><p className="mb-30 opacity">
                                    <p>
                                    Digital solutions continue to evolve rapidly, impacting various sectors and industries, from healthcare and finance to entertainment and transportation. These technologies often offer improved efficiency, convenience, and accessibility, but they also raise concerns about privacy, security, and ethical considerations, which need to be carefully addressed.
                                    </p>
                                    "Digital solutions" is a broad term that encompasses a wide range of technology-based answers to problems or challenges. These solutions leverage digital technologies, such as software, hardware, data, and the internet, to address specific needs and enhance various aspects of business, society, and individual life.</p></div>
                                <div className="progress_one mb-20 hidden">
                                    <span className="fs-20 fw-500 position-relative">Website Development</span>
                                    <div className="progress mt-20 position-relative">
                                        <div className="progress-bar bar-width-one bg-dark" role="progressbar" aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}></div>
                                    </div>
                                </div>
                                <div className="progress_two mb-20 hidden">
                                    <span className="fs-20 fw-500 position-relative">Digital Products</span>
                                    <div className="progress mt-20 position-relative">
                                        <div className="progress-bar bar-width-two bg-dark" role="progressbar" aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}></div>
                                    </div>
                                </div>
                                <div className="progress_three hidden">
                                    <span className="fs-20 fw-500 position-relative">Website Development</span>
                                    <div className="progress mt-20 position-relative">
                                        <div className="progress-bar bar-width-three bg-dark" role="progressbar" aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="right-choose position-relative pl-70 lg-pl-0">
                                <img src="images/about/about-07.png" alt="" className="about-02" />
                                <div className="svg-two">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="97" height="97" viewBox="0 0 97 97" fill="none">
                                        <circle cx="48.5" cy="48.5" r="48.5" fill="#FF7222" />
                                    </svg>
                                </div>
                                <div className="shapes shapes-02"></div>
                                <img src="images/shape/shape-17.svg" alt="" className="shapes shape-05" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}